import React from "react";
import { string, number, array, object } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faPencilAlt,
  faComments,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";

import { makeLinkFromName } from "shared/functions";
import { SrebniakLink } from "components/SrebniakLink";
import { entryIcon } from "./styles.module.scss";

export const EntryInfo = ({
  date,
  author,
  commentCounter,
  categories,
  path,
  className,
}) => (
  <div className={className}>
    <div>
      <FontAwesomeIcon icon={faClock} className={entryIcon} />
      {path && date ? <SrebniakLink to={path}>{date}</SrebniakLink> : date}
    </div>
    <div>
      {author && (
        <>
          <FontAwesomeIcon icon={faPencilAlt} className={entryIcon} />
          <SrebniakLink
            to={`/author/${
              author.link
                ? makeLinkFromName(author.link)
                : makeLinkFromName(author.name)
            }`}
          >
            {author.name}
          </SrebniakLink>
        </>
      )}
    </div>
    {!!commentCounter && (
      <>
        <FontAwesomeIcon icon={faComments} className={entryIcon} />
        {commentCounter === 0 ? "No comments" : commentCounter + " Comments"}
      </>
    )}
    {categories && (
      <div>
        <FontAwesomeIcon icon={faFolderOpen} className={entryIcon} />
        Categories:
        {categories.map((s, i) => {
          let subcategoryOf = "";
          const link = s.link ? s.link : s.name;
          if (s.subcategoryOf) {
            subcategoryOf = makeLinkFromName(s.subcategoryOf);
          }
          return i !== categories.length - 1 ? (
            <SrebniakLink
              key={i}
              to={`/kategoria/${subcategoryOf + makeLinkFromName(link)}`}
            >
              {` ${s.name}`},
            </SrebniakLink>
          ) : (
            <SrebniakLink
              key={i}
              to={`/kategoria/${subcategoryOf + makeLinkFromName(link)}`}
            >
              {` ${s.name}`}
            </SrebniakLink>
          );
        })}
      </div>
    )}
  </div>
);

EntryInfo.propTypes = {
  date: string,
  author: object,
  commentCounter: number,
  categories: array,
  path: string,
  className: string,
};

EntryInfo.defaultProps = {
  date: "",
  author: null,
  commentCounter: 0,
  categories: null,
  path: "",
  className: "",
};
