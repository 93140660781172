import React from "react";
import { string, number, array, object } from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import cn from "classnames";

import { TextContent } from "components/TextContent";
import { SrebniakLink } from "components/SrebniakLink";
import { EntryInfo } from "components/EntryInfo";

import {
  blogEntryButton,
  blogEntryContainer,
  blogEntryImage,
  blogEntryDescription,
} from "./styles.module.scss";

export const BlogEntry = ({
  title,
  date,
  author,
  commentCounter,
  categories,
  heroImage,
  description,
  path,
  className,
}) => {
  const image = getImage(heroImage);
  return (
    <div className={cn(blogEntryContainer, className)}>
      <TextContent title={title} path={path}>
        <EntryInfo
          date={date}
          author={author}
          commentCounter={commentCounter}
          categories={categories}
          path={path}
        />
        {image &&
          (path ? (
            <SrebniakLink to={path}>
              <GatsbyImage className={blogEntryImage} image={image} alt="" />
            </SrebniakLink>
          ) : (
            <GatsbyImage className={blogEntryImage} image={image} alt="" />
          ))}
        <div className={blogEntryDescription}>{description}</div>
      </TextContent>
      {path && (
        <Link className={blogEntryButton} to={path}>
          Read More
        </Link>
      )}
    </div>
  );
};

BlogEntry.propTypes = {
  title: string,
  date: string,
  author: object,
  commentCounter: number,
  categories: array,
  heroImage: object,
  description: string,
  path: string,
  className: string,
};

BlogEntry.defaultProps = {
  title: "",
  date: "",
  author: null,
  commentCounter: 0,
  categories: null,
  heroImage: null,
  description: "",
  path: "",
  className: "",
};
