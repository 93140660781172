import React from "react";
import { string, array, element, oneOfType } from "prop-types";

import { codeFragmentContainer } from "./styles.module.scss";

export const CodeFragment = ({ children }) => (
  <div className={codeFragmentContainer}>{children}</div>
);

CodeFragment.propTypes = {
  children: oneOfType([string, array, element]),
};

CodeFragment.defaultProps = {
  children: "",
};
