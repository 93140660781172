import React from "react";
import { string, array, oneOfType, element } from "prop-types";

import { flexContainer } from "./styles.module.scss";

export const FlexContainer = ({ children }) => (
  <div className={flexContainer}>{children}</div>
);

FlexContainer.propTypes = {
  children: oneOfType([string, array, element]),
};

FlexContainer.defaultProps = {
  children: "",
};
