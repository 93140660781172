import { TextContent } from "components/TextContent";
import { SrebniakLink } from "components/SrebniakLink";
import { BlogEntry } from "components/BlogEntry";
import { ContentBanner } from "components/ContentBanner";
import { BannerLink } from "components/BannerLink";
import * as React from 'react';
export default {
  TextContent,
  SrebniakLink,
  BlogEntry,
  ContentBanner,
  BannerLink,
  React
};