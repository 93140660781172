import React from "react";
import { string, array, element, oneOfType, bool } from "prop-types";
import cn from "classnames";

import {
  quoteContainer,
  quoteCenteredContainer,
  authorContainer,
  authorText,
} from "./styles.module.scss";

export const Quote = ({ children, author, centered }) => (
  <div
    className={cn(quoteContainer, {
      [quoteCenteredContainer]: centered,
    })}
  >
    {children}
    <div className={authorContainer}>
      {!!author && <div className={authorText}>- {author}</div>}
    </div>
  </div>
);

Quote.propTypes = {
  children: oneOfType([string, array, element]),
  author: string,
  centered: bool,
};

Quote.defaultProps = {
  children: "",
  author: "",
  centered: false,
};
