import React from "react";
import { string, bool, object } from "prop-types";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { srebniakImage } from "./styles.module.scss";

export const SrebniakImage = ({ image, isOnRight, alt, centered }) => (
  <div style={{ textAlign: centered ? "center" : "" }}>
    <GatsbyImage
      className={srebniakImage}
      style={{ float: isOnRight ? "right" : "" }}
      image={getImage(image)}
      alt={alt}
    />
  </div>
);

SrebniakImage.propTypes = {
  image: object.isRequired,
  isOnRight: bool,
  alt: string.isRequired,
  centered: bool,
};

SrebniakImage.defaultProps = {
  isOnRight: false,
  centered: false,
};
