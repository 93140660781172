import React from "react";
import cn from "classnames";
import { SrebniakLink } from "components/SrebniakLink";
import {
  string,
  array,
  oneOfType,
  element,
  bool,
  number,
  object,
} from "prop-types";

import {
  titleText,
  titleLinkText,
  contentText,
  centeredText,
  categoryText,
} from "./styles.module.scss";

export const TextContent = ({
  title,
  children,
  centered,
  isTitleBig,
  className,
  marginBottom,
  marginTop,
  style,
  path,
}) => (
  <div>
    <div
      style={style}
      className={cn(titleText, { [categoryText]: isTitleBig })}
    >
      {path ? (
        <SrebniakLink
          to={path}
          className={cn(titleLinkText, {
            [categoryText]: isTitleBig,
          })}
        >
          {title}
        </SrebniakLink>
      ) : (
        title
      )}
    </div>
    <div
      className={cn(contentText, className, {
        [centeredText]: centered,
      })}
      style={{ marginTop, marginBottom, ...style }}
    >
      {children}
    </div>
  </div>
);

TextContent.propTypes = {
  title: string,
  children: oneOfType([string, array, element]),
  centered: bool,
  isTitleBig: bool,
  className: string,
  marginBottom: number,
  marginTop: number,
  style: object,
  path: string,
};

TextContent.defaultProps = {
  title: "",
  children: "",
  centered: false,
  isTitleBig: false,
  className: "",
  marginBottom: 24,
  marginTop: 0,
  style: {},
  path: "",
};
