import { TextContent } from "components/TextContent";
import { SrebniakLink } from "components/SrebniakLink";
import { SrebniakImage } from "components/SrebniakImage";
import { FlexContainer } from "components/FlexContainer";
import { SrebniakVideo } from "components/SrebniakVideo";
import * as React from 'react';
export default {
  TextContent,
  SrebniakLink,
  SrebniakImage,
  FlexContainer,
  SrebniakVideo,
  React
};