import React from "react";
import { string, bool } from "prop-types";

import { srebniakVideo } from "./styles.module.scss";

export const SrebniakVideo = ({
  src,
  width,
  height,
  centered,
  isOnRight,
  ...props
}) => (
  <div style={{ textAlign: centered ? "center" : "" }}>
    <iframe
      src={src}
      style={{ float: isOnRight ? "right" : "" }}
      className={srebniakVideo}
      width={width}
      height={height}
      {...props}
    ></iframe>
  </div>
);

SrebniakVideo.propTypes = {
  isOnRight: bool,
  centered: bool,
  src: string.isRequired,
  width: string.isRequired,
  height: string.isRequired,
};

SrebniakVideo.defaultProps = {
  isOnRight: false,
  centered: false,
};
