import { TextContent } from "components/TextContent";
import { FileColumn } from "components/FileColumn";
import { FlexContainer } from "components/FlexContainer";
import { CodeFragment } from "components/CodeFragment";
import { Quote } from "components/Quote";
import * as React from 'react';
export default {
  TextContent,
  FileColumn,
  FlexContainer,
  CodeFragment,
  Quote,
  React
};