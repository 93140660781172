import React from "react";
import cn from "classnames";
import { string, array, oneOfType, element, object } from "prop-types";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import { TextContent } from "components/TextContent";
import {
  contentBannerContainer,
  descriptionContainer,
  descriptionText,
  contentBannerImage,
  childrenContainer,
} from "./styles.module.scss";

export const ContentBanner = ({
  topImage,
  children,
  description,
  descriptionTitle,
  backgroundColor,
  className,
  textColor,
}) => (
  <div
    className={cn(contentBannerContainer, className)}
    style={{ backgroundColor }}
  >
    {topImage && (
      <GatsbyImage
        className={contentBannerImage}
        image={getImage(topImage)}
        alt="content_banner_image"
      />
    )}
    <div className={descriptionContainer}>
      <TextContent
        title={descriptionTitle}
        style={{ color: textColor }}
        className={descriptionText}
        isTitleBig
      >
        {description}
      </TextContent>
    </div>
    <div className={childrenContainer}>{children}</div>
  </div>
);

ContentBanner.propTypes = {
  topImage: object,
  children: oneOfType([string, array, element]),
  description: string,
  descriptionTitle: string,
  backgroundColor: string,
  textColor: string,
  className: string,
};

ContentBanner.defaultProps = {
  topImage: {},
  children: "",
  description: "",
  descriptionTitle: "",
  backgroundColor: "",
  className: "",
  textColor: "",
};
