import React from "react";
import { string, array, oneOfType, element } from "prop-types";

export const SrebniakHighlight = ({ children, color, backgroundColor }) => (
  <code
    style={{ backgroundColor, color, marginRight: "5px", marginLeft: "5px" }}
  >
    {children}
  </code>
);

SrebniakHighlight.propTypes = {
  children: oneOfType([string, array, element]),
  color: string,
  backgroundColor: string,
};

SrebniakHighlight.defaultProps = {
  children: "",
  color: "",
  backgroundColor: "",
};
