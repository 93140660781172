/* eslint-disable import/no-namespace */
/* eslint-disable import/namespace */
import React from "react";
import { string } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as brandIcons from "@fortawesome/free-brands-svg-icons";
import * as solidIcons from "@fortawesome/free-solid-svg-icons";
import * as regularIcons from "@fortawesome/free-regular-svg-icons";

import {
  bannerLinkContainer,
  bannerLinkTitle,
  bannerLinkTextContainer,
  bannerLinkText,
  bannerLinkIcon,
} from "./styles.module.scss";

export const BannerLink = ({
  fontAwesomeIconName,
  title,
  link,
  linkTitle,
  borderColor,
}) => (
  <div
    className={bannerLinkContainer}
    style={{ border: "1px solid " + borderColor }}
  >
    <FontAwesomeIcon
      icon={
        brandIcons[fontAwesomeIconName] ||
        solidIcons[fontAwesomeIconName] ||
        regularIcons[fontAwesomeIconName]
      }
      className={bannerLinkIcon}
    />
    <div className={bannerLinkTitle}>{title}</div>
    <div className={bannerLinkTextContainer}>
      <a className={bannerLinkText} href={link}>
        {linkTitle}
      </a>
    </div>
  </div>
);

BannerLink.propTypes = {
  fontAwesomeIconName: string,
  title: string,
  link: string,
  linkTitle: string,
  borderColor: string,
};

BannerLink.defaultProps = {
  fontAwesomeIconName: "",
  title: "",
  link: "",
  linkTitle: "",
  borderColor: "",
};
