import { TextContent } from "components/TextContent";
import { SrebniakImage } from "components/SrebniakImage";
import { CodeFragment } from "components/CodeFragment";
import { SrebniakLink } from "components/SrebniakLink";
import * as React from 'react';
export default {
  TextContent,
  SrebniakImage,
  CodeFragment,
  SrebniakLink,
  React
};