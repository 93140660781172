import React from "react";
import { Link } from "gatsby";
import cn from "classnames";
import { oneOfType, string, array, element, bool } from "prop-types";

import { linkText, bigLinkText, dateContainer } from "./styles.module.scss";
import { EntryInfo } from "components/EntryInfo";
import { refactorDate } from "shared/functions";

export const SrebniakLink = ({ children, to, internal, className, date }) => (
  <>
    {date ? (
      <>
        <Link to={to} className={cn(bigLinkText, className)}>
          {children}
        </Link>
        <EntryInfo date={refactorDate(date)} className={dateContainer} />
      </>
    ) : internal ? (
      <Link to={to} className={cn(linkText, className)}>
        {children}
      </Link>
    ) : (
      <a href={to} className={cn(linkText, className)}>
        {children}
      </a>
    )}
  </>
);

SrebniakLink.propTypes = {
  children: oneOfType([string, array, element]),
  to: string.isRequired,
  internal: bool,
  className: string,
  date: string,
};

SrebniakLink.defaultProps = {
  children: "",
  internal: true,
  className: "",
  date: "",
};
