export const checkPathname = (pathname, location) =>
  location ? location.pathname.includes(pathname) : false;

export const strictCheckPathname = (pathname, location) =>
  location ? location.pathname === pathname : false;

export const refactorDate = (date) => {
  let elements = date.split("-");
  elements[2] = elements[2].split(" ")[0];
  elements = elements.reverse();
  const months = [
    "stycznia",
    "lutego",
    "marca",
    "kwietnia",
    "maja",
    "czerwca",
    "lipca",
    "sierpnia",
    "września",
    "października",
    "listopada",
    "grudnia",
  ];
  const firstElem = elements[0][0] === "0" ? elements[0][1] : elements[0];
  return firstElem + " " + months[elements[1] - 1] + " " + elements[2];
};

export const hashIndexForMap = (index) => new Date().getTime() + index;

const unPolishString = (word) =>
  word
    .split("")
    .map((element) => {
      switch (element) {
        case "ą":
          return "a";
        case "ć":
          return "c";
        case "ę":
          return "e";
        case "ł":
          return "l";
        case "ń":
          return "n";
        case "ó":
          return "o";
        case "ś":
          return "s";
        case "ż":
          return "z";
        case "ź":
          return "z";
      }
      return element;
    })
    .join("");

export const makeLinkFromName = (name) => {
  const array = name.split(" ");
  return (
    array
      .map((s, i) =>
        i !== array.length - 1
          ? unPolishString(s.toLowerCase()) + "-"
          : unPolishString(s.toLowerCase())
      )
      .join("") + "/"
  );
};

export const makePostsLinkDate = (date) => {
  let result = "";
  date.split("-").forEach((s) => {
    if (s.split(" ")[1] !== undefined) {
      result += s.split(" ")[0] + "/";
    } else {
      result += s + "/";
    }
  });
  return result;
};
