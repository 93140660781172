exports.components = {
  "component---src-components-authors-js": () => import("./../../../src/components/authors.js" /* webpackChunkName: "component---src-components-authors-js" */),
  "component---src-components-categories-js": () => import("./../../../src/components/categories.js" /* webpackChunkName: "component---src-components-categories-js" */),
  "component---src-components-post-js": () => import("./../../../src/components/post.js" /* webpackChunkName: "component---src-components-post-js" */),
  "component---src-components-post-list-js": () => import("./../../../src/components/postList.js" /* webpackChunkName: "component---src-components-post-list-js" */),
  "component---src-components-thematic-js": () => import("./../../../src/components/thematic.js" /* webpackChunkName: "component---src-components-thematic-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-kim-jestem-index-js": () => import("./../../../src/pages/kim-jestem/index.js" /* webpackChunkName: "component---src-pages-kim-jestem-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-watki-tematyczne-index-js": () => import("./../../../src/pages/watki-tematyczne/index.js" /* webpackChunkName: "component---src-pages-watki-tematyczne-index-js" */)
}

