import React from "react";
import { array } from "prop-types";

import { fileColumn } from "./styles.module.scss";

export const FileColumn = ({ items }) => (
  <div className={fileColumn}>
    {items.map((item) => (
      <div key={item}>{item}</div>
    ))}
  </div>
);

FileColumn.propTypes = {
  items: array,
};

FileColumn.defaultProps = {
  items: [],
};
